import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Every month doctors from PCMH Restore Health Center volunteer at the 'Mathru Devo Bhava' Camp organised by Satya Sai Baba Foundation conducted at Primary Health Centers in Chikkaballapur, spread across the entire district. Doctors from various parts of Bengaluru City volunteer at this Camp to provide ante natal check ups to pregnant women, counselling and identifying high risk cases to take the needful action.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "740px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3b6eb8bdffd892d39295d6ecde957354/ca4a8/chikkaballapur-mathru-devo-bhava-camp-1.webp",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRrwAAABXRUJQVlA4ILAAAAAQBQCdASoUAA8APtFUo0uoJKMhsAgBABoJagCdMoMpJnVFsbMk2zULZQjp+RY0p18AAP7n3qW9e7YnCrOYEMjHChEjgCC6ZLLoq6IGKCsQvtwyqYmpRbw/USnz05nQ7R4KZQ5qB6w8WxjD3vZz5karFgB6pAg8/7u3X5dah/smstfz3e3pYpAj7SnTeDB/0hbtYsainh04mMsmeCz3LELHun3rRjo0i7unTpT4PRYAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Team at Chikkaballapur Mathru Devo Bhava Camp",
            "title": "Team at Chikkaballapur Mathru Devo Bhava Camp",
            "src": "/static/3b6eb8bdffd892d39295d6ecde957354/ca4a8/chikkaballapur-mathru-devo-bhava-camp-1.webp",
            "srcSet": ["/static/3b6eb8bdffd892d39295d6ecde957354/c85cb/chikkaballapur-mathru-devo-bhava-camp-1.webp 300w", "/static/3b6eb8bdffd892d39295d6ecde957354/e88ff/chikkaballapur-mathru-devo-bhava-camp-1.webp 600w", "/static/3b6eb8bdffd892d39295d6ecde957354/ca4a8/chikkaballapur-mathru-devo-bhava-camp-1.webp 740w"],
            "sizes": "(max-width: 740px) 100vw, 740px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      